import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineEye, AiOutlineCloseCircle } from 'react-icons/ai';
import { BsHeart, BsShare, BsPlus, BsHeartFill } from "react-icons/bs";
import { BiMinus, BiLink } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../api/api';
// import Pagination from 'react-js-pagination';
import { toast } from 'react-toastify';
import { setSelectedProductId } from '../../utils/manageLocalStorage';
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import Loader from '../loader/Loader';
import No_Orders from '../../utils/zero-state-screens/No_Orders.svg';
import QuickViewModal from './QuickViewModal';
import { IoIosArrowDown } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Range, getTrackBackground } from 'react-range';
import { setCategory } from '../../model/reducer/categoryReducer';
import { setFilterBrands, setFilterByCountry, setFilterBySeller, setFilterCategory, setFilterMinMaxPrice, setFilterProductSizes, setFilterSearch, setFilterSection, setFilterSort } from '../../model/reducer/productFilterReducer';
import { setSelectedProduct } from '../../model/reducer/selectedProduct';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Popup from "../same-seller-popup/Popup";
import { addtoGuestCart, setCart, setCartProducts, setCartSubTotal, setSellerFlag } from '../../model/reducer/cartReducer';
import { setFavouriteLength, setFavouriteProductIds } from '../../model/reducer/favouriteReducer';
import { LuStar } from 'react-icons/lu';
import "./product.css";
import CategoryComponent from './Categories';
import { MdSignalWifiConnectedNoInternet0 } from "react-icons/md";
import ImageWithPlaceholder from '../image-with-placeholder/ImageWithPlaceholder';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Navigation, Thumbs, Mousewheel, Autoplay, Pagination } from "swiper/modules";
import 'swiper/css/pagination';
import ProductCard from './ProductCard';


const ProductHome = React.memo(() => {
    const total_products_per_page = 16;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const { slug } = useParams();

    const closeCanvas = useRef();
    const category = useSelector(state => state.category?.category);
    const city = useSelector(state => state.city);
    const favorite = useSelector(state => (state.favourite));
    const setting = useSelector(state => (state.setting));
    const cart = useSelector(state => (state.cart));
    const user = useSelector(state => (state.user));
    const share_parent_url = `${setting.setting && setting.setting.web_settings.website_url}/product/`;

    const [productresult, setproductresult] = useState([]);
    const [selectedProduct, setselectedProduct] = useState({});
    const [offset, setoffset] = useState(0);
    const [totalProducts, settotalProducts] = useState(0);
    const [currPage, setcurrPage] = useState(1);
    const [isLoader, setisLoader] = useState(false);
    const [selectedVariant, setSelectedVariant] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [p_id, setP_id] = useState(0);
    const [p_v_id, setP_V_id] = useState(0);
    const [qnty, setQnty] = useState(0);
    const location = useLocation();
    const [showPriceFilter, setShowPriceFilter] = useState(true);
    const [networkError, setNetworkError] = useState(false);
    const { t } = useTranslation();
    const [similarProductsLength, setSimilarProductsLength] = useState(null)
    const [similarProducts, setSimilarProducts] = useState([])
    const [productdata, setproductdata] = useState({});

    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const productLimit = 10;

    const fetchMoreRelatedProducts = () => {
        if (similarProductsLength > similarProducts?.length) {
            const filter = {
                limit: productLimit,
                offset: offset + 10
            }
            api.getProductbyFilter(city.city?.latitude ? city.city?.latitude : setting?.setting?.default_city?.latitude, city.city?.longitude ? city.city?.longitude : setting?.setting?.default_city?.longitude, filter, user?.jwtToken, productdata?.tag_names).then((res) => res.json()).then((result => {
                setSimilarProducts((simi_prdct) => [...simi_prdct, ...result?.data])
            }))
        }
    }
    useEffect(() => {
        document.title = "Buy " + category + " at Best Price on Beldara | 10 minutes delivery"
    }, []);
    const filterProductsFromApi = async () => {
        setisLoader(true);

        await api.getShop(city?.city?.latitude, city?.city?.longitude, user?.jwtToken)
            .then(response => response.json())
            .then(result => {

                if (result.status === 1) {
                    console.log("Filter Product From Api ->", result);

                    setproductresult(result.data);

                    settotalProducts(result.total);
                }
                else {
                    setproductresult([]);
                    settotalProducts(0);
                }
                setisLoader(false);
            })
            .catch(error => {
                const regex = /Failed to fetch/g;
                if (regex.test(error.message)) {
                    console.log("Network Error");
                    setNetworkError(true);
                }
                console.log(error.message);
            });
    };


    const sort_unique_brand_ids = (int_brand_ids) => {
        if (int_brand_ids.length === 0) return int_brand_ids;
        int_brand_ids = int_brand_ids.sort(function (a, b) { return a * 1 - b * 1; });
        var ret = [int_brand_ids[0]];
        for (var i = 1; i < int_brand_ids.length; i++) { //Start loop at 1: arr[0] can never be a duplicate
            if (int_brand_ids[i - 1] !== int_brand_ids[i]) {
                ret.push(int_brand_ids[i]);
            }
        }
        return ret;
    };

    // console.log(category?.category);

    useEffect(() => {



        // if (location.pathname === "/products")
        filterProductsFromApi({

            limit: total_products_per_page,
            offset: offset,

        });

    }, [offset]);


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);



    const placeHolderImage = (e) => {

        e.target.src = setting.setting?.web_logo;
    };




    // 
    const addtoCart = async (product_id, product_variant_id, qty) => {
        setP_id(product_id);
        setP_V_id(product_variant_id);
        setQnty(qty);
        await api.addToCart(user?.jwtToken, product_id, product_variant_id, qty)
            .then(response => response.json())
            .then(async (result) => {
                if (result.status === 1) {
                    // toast.success(result.message);
                    if (cart?.cartProducts?.find((product) => ((product?.product_id == product_id) && (product?.product_variant_id == product_variant_id)))?.qty == undefined) {
                        dispatch(setCart({ data: result }));
                        dispatch(setCartSubTotal({ data: result?.data?.sub_total }));
                        const updatedCartCount = [...cart?.cartProducts, { product_id: product_id, product_variant_id: product_variant_id, qty: qty }];
                        dispatch(setCartProducts({ data: updatedCartCount }));
                    } else {
                        const updatedProducts = cart?.cartProducts?.map(product => {
                            if (((product.product_id == product_id) && (product?.product_variant_id == product_variant_id))) {
                                return { ...product, qty: qty };
                            } else {
                                return product;
                            }
                        });
                        dispatch(setCart({ data: result }));
                        dispatch(setCartProducts({ data: updatedProducts }));
                        dispatch(setCartSubTotal({ data: result?.data?.sub_total }));
                    }
                }
                else if (result?.data?.one_seller_error_code == 1) {
                    dispatch(setSellerFlag({ data: 1 }));
                    // console.log(result.message);
                    // toast.error(t(`${result.message}`));
                } else {
                    toast.error(result.message);
                }
                // setisLoader(false);
            });
    };

    //remove from Cart
    const removefromCart = async (product_id, product_variant_id) => {
        await api.removeFromCart(user?.jwtToken, product_id, product_variant_id)
            .then(response => response.json())
            .then(async (result) => {
                if (result.status === 1) {
                    // toast.success(result.message);
                    const updatedProducts = cart?.cartProducts?.filter(product => ((product?.product_id != product_id) && (product?.product_variant_id != product_variant_id)));
                    dispatch(setCartProducts({ data: updatedProducts }));
                }
                else {
                    toast.error(result.message);
                }
            });
    };

    //Add to favorite
    const addToFavorite = async (product_id) => {
        await api.addToFavotite(user?.jwtToken, product_id)
            .then(response => response.json())
            .then(async (result) => {
                if (result.status === 1) {
                    // toast.success(result.message);
                    const updatedFavouriteProducts = [...favorite.favouriteProductIds, product_id];
                    dispatch(setFavouriteProductIds({ data: updatedFavouriteProducts }));
                    const updatedFavouriteLength = favorite?.favouritelength + 1;
                    dispatch(setFavouriteLength({ data: updatedFavouriteLength }));
                }
                else {
                    // setisLoader(false);
                    toast.error(result.message);
                }
            });
    };

    const removefromFavorite = async (product_id) => {
        await api.removeFromFavorite(user?.jwtToken, product_id)
            .then(response => response.json())
            .then(async (result) => {
                if (result.status === 1) {
                    // toast.success(result.message);
                    const updatedFavouriteProducts = favorite?.favouriteProductIds.filter(id => id != product_id);
                    dispatch(setFavouriteProductIds({ data: updatedFavouriteProducts }));
                    const updatedFavouriteLength = favorite?.favouritelength - 1;
                    dispatch(setFavouriteLength({ data: updatedFavouriteLength }));
                }
                else {
                    toast.error(result.message);
                }
            });
    };

    //page change
    const handlePageChange = (pageNum) => {
        setcurrPage(pageNum);
        setoffset(pageNum * total_products_per_page - total_products_per_page);
    };

    const placeholderItems = Array.from({ length: 12 }).map((_, index) => index);

    function getProductQuantities(products) {
        return Object.entries(products?.reduce((quantities, product) => {
            const existingQty = quantities[product.product_id] || 0;
            return { ...quantities, [product.product_id]: existingQty + product.qty };
        }, {})).map(([productId, qty]) => ({
            product_id: parseInt(productId),
            qty
        }));
    }


    const handleValidateAddExistingProduct = (productQuantity, product) => {
        console.log("product quantity from product list page", productQuantity)
        if (Number(product.is_unlimited_stock)) {
            if (productQuantity?.find(prdct => prdct?.product_id == product?.id)?.qty < Number(product?.total_allowed_quantity)) {
                addtoCart(product.id, product.variants[0].id, cart?.cartProducts?.find(prdct => prdct?.product_variant_id == product.variants[0].id)?.qty + 1);
            } else {
                toast.error('Apologies, maximum product quantity limit reached!');
            }
        } else {
            if (productQuantity?.find(prdct => prdct?.product_id == product?.id)?.qty >= Number(product.variants[0].stock)) {
                toast.error(t("out_of_stock_message"));
            }
            else if (Number(productQuantity?.find(prdct => prdct?.product_id == product?.id)?.qty) >= Number(product.total_allowed_quantity)) {
                toast.error('Apologies, maximum product quantity limit reached');
            } else {
                addtoCart(product.id, product.variants[0].id, cart?.cartProducts?.find(prdct => prdct?.product_variant_id == product.variants[0].id)?.qty + 1);
            }
        }
    };

    const handleValidateAddNewProduct = (productQuantity, product) => {
        if (user?.jwtToken !== "") {
            if ((productQuantity?.find(prdct => prdct?.product_id == product?.id)?.qty || 0) >= Number(product?.total_allowed_quantity)) {
                toast.error('Oops, Limited Stock Available');
            }
            else if (Number(product.is_unlimited_stock)) {
                addtoCart(product.id, product?.variants?.[0].id, 1);
            } else {
                if (product?.variants?.[0]?.status) {
                    addtoCart(product.id, product?.variants?.[0].id, 1);
                } else {
                    toast.error('Oops, Limited Stock Available');
                }
            }
        }
        else {
            toast.error(t("required_login_message_for_cartRedirect"));
        }
    };

    const handleAddNewProductGuest = (productQuantity, product) => {
        if ((productQuantity?.find(prdct => prdct?.product_id == product?.id)?.qty || 0) < Number(product.total_allowed_quantity)) {
            AddToGuestCart(product.id, product.variants[0].id, 1, 0);
        } else {
            toast.error(t("out_of_stock_message"));
        }
    };
    const AddToGuestCart = (productId, productVariantId, Qty, isExisting) => {
        if (isExisting) {
            const updatedProducts = Qty !== 0 ? cart?.guestCart?.map((product) => {
                if (product?.product_id == productId && product?.product_variant_id == productVariantId) {
                    return { ...product, qty: Qty };
                } else {
                    return product;
                }
            }) : cart?.guestCart?.filter(product => product?.product_id != productId && product?.productVariantId != productVariantId);
            dispatch(addtoGuestCart({ data: updatedProducts }));
        } else {
            const productData = { product_id: productId, product_variant_id: productVariantId, qty: Qty };
            dispatch(addtoGuestCart({ data: [...cart?.guestCart, productData] }));
        }
    };

    const handleValidateAddExistingGuestProduct = (productQuantity, product, quantity) => {
        if (Number(product.is_unlimited_stock)) {
            if (productQuantity?.find(prdct => prdct?.product_id == product?.id)?.qty >= Number(product?.total_allowed_quantity)) {
                toast.error('Apologies, maximum product quantity limit reached');
            }
            else {
                AddToGuestCart(product?.id, product?.variants?.[0]?.id, quantity, 1);
            }
        }
        else {
            if (productQuantity?.find(prdct => prdct?.product_id == product?.id)?.qty >= Number(product?.variants?.[0]?.stock)) {
                toast.error('Oops, Limited Stock Available');
            }
            else if (productQuantity?.find(prdct => prdct?.product_id == product?.id)?.qty >= Number(product?.total_allowed_quantity)) {
                toast.error('Apologies, maximum cart quantity limit reached');
            }
            else {
                AddToGuestCart(product?.id, product?.variants?.[0]?.id, quantity, 1);
            }
        }
    };

    return (
        <>
            {!networkError ?
                <>

                    <section id="productlist" className='container' onContextMenu={() => { return false; }}>

                        <div className='row justify-content-center' id='products'>
                            {/* products according to applied filter */}
                            <div className='d-flex flex-column col-md-12 col-12 h-10 productList_container' style={{ gap: '10px' }}>
                                <div className="row">
                                    {/* {console.log(totalProducts, isLoader)} */}




                                    {productresult === null || isLoader
                                        ? (
                                            <>
                                                <div className='h-100 productList_content'>
                                                    <div className='row flex-wrap'>
                                                        {placeholderItems.map((index) => (
                                                            <div key={index} className={`${'col-6 list-view'}`}>
                                                                <Skeleton height={330} className='mt-3' borderRadius={8} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                            </>
                                        )
                                        : (
                                            <>

                                                {productresult.BestPrice1 != null && productresult.BestPrice1.length > 0 && isLoader == false
                                                    ? (
                                                        <div>
                                                            <div className='d-flex m-3 col-12 flex-row justify-content-between align-items-center filter-view'>
                                                                <div className='d-flex gap-3'>
                                                                    <span className='total_product_count'> {productresult.BestPrice1[0].short_description}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row ">
                                                                <Swiper style={{
                                                                    '--swiper-navigation-color': '#fff',
                                                                    '--swiper-pagination-color': '#fff',
                                                                }}
                                                                    breakpoints={{
                                                                        0: {
                                                                            slidesPerView: 1,
                                                                            spaceBetween: 6
                                                                        },
                                                                        500: {
                                                                            slidesPerView: 3,
                                                                            spaceBetween: 10
                                                                        },
                                                                        992: {
                                                                            slidesPerView: 5,
                                                                            spaceBetween: 30
                                                                        }
                                                                    }}

                                                                    navigation={true}
                                                                    thumbs={{ swiper: thumbsSwiper }}
                                                                    modules={[Navigation, Thumbs, Mousewheel, Autoplay, Pagination]}

                                                                    onReachEnd={() => {
                                                                        fetchMoreRelatedProducts()
                                                                    }}

                                                                >

                                                                    {productresult.BestPrice1[0].products?.map((products, index) => (
                                                                        <div className="" key={products?.id}>
                                                                            <SwiperSlide>
                                                                                <ProductCard product={products} />
                                                                            </SwiperSlide>
                                                                        </div>

                                                                    ))}
                                                                </Swiper>
                                                            </div>
                                                            <div className='d-flex m-3 col-12 flex-row justify-content-between align-items-center filter-view'>
                                                                <div className='d-flex gap-3'>
                                                                    <span className='total_product_count'> {productresult.BestPrice2[0].short_description}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <Swiper style={{
                                                                    '--swiper-navigation-color': '#fff',
                                                                    '--swiper-pagination-color': '#fff',
                                                                }}
                                                                    breakpoints={{
                                                                        0: {
                                                                            slidesPerView: 1,
                                                                            spaceBetween: 6
                                                                        },
                                                                        500: {
                                                                            slidesPerView: 3,
                                                                            spaceBetween: 10
                                                                        },
                                                                        992: {
                                                                            slidesPerView: 5,
                                                                            spaceBetween: 30
                                                                        }
                                                                    }}

                                                                    navigation={true}
                                                                    thumbs={{ swiper: thumbsSwiper }}
                                                                    modules={[Navigation, Thumbs, Mousewheel, Autoplay, Pagination]}

                                                                    onReachEnd={() => {
                                                                        fetchMoreRelatedProducts()
                                                                    }}

                                                                >

                                                                    {productresult.BestPrice2[0].products?.map((products, index) => (
                                                                        <div className="" key={products?.id}>
                                                                            <SwiperSlide>
                                                                                <ProductCard product={products} />
                                                                            </SwiperSlide>
                                                                        </div>

                                                                    ))}
                                                                </Swiper>
                                                            </div>
                                                            <div className='d-flex m-3 col-12 flex-row justify-content-between align-items-center filter-view'>
                                                                <div className='d-flex gap-3'>
                                                                    <span className='total_product_count'> {productresult.sections_offers[0].short_description}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <Swiper style={{
                                                                    '--swiper-navigation-color': '#fff',
                                                                    '--swiper-pagination-color': '#fff',
                                                                }}
                                                                    breakpoints={{
                                                                        0: {
                                                                            slidesPerView: 1,
                                                                            spaceBetween: 6
                                                                        },
                                                                        500: {
                                                                            slidesPerView: 3,
                                                                            spaceBetween: 10
                                                                        },
                                                                        992: {
                                                                            slidesPerView: 5,
                                                                            spaceBetween: 30
                                                                        }
                                                                    }}

                                                                    navigation={true}
                                                                    thumbs={{ swiper: thumbsSwiper }}
                                                                    modules={[Navigation, Thumbs, Mousewheel, Autoplay, Pagination]}

                                                                    onReachEnd={() => {
                                                                        fetchMoreRelatedProducts()
                                                                    }}

                                                                >

                                                                    {productresult.sections_offers[0].products?.map((products, index) => (
                                                                        <div className="" key={products?.id}>
                                                                            <SwiperSlide>
                                                                                <ProductCard product={products} />
                                                                            </SwiperSlide>
                                                                        </div>

                                                                    ))}
                                                                </Swiper>
                                                            </div>
                                                            <div className='d-flex m-3 col-12 flex-row justify-content-between align-items-center filter-view'>
                                                                <div className='d-flex gap-3'>
                                                                    <span className='total_product_count'> {productresult.sections_personal[0].short_description}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <Swiper style={{
                                                                    '--swiper-navigation-color': '#fff',
                                                                    '--swiper-pagination-color': '#fff',
                                                                }}
                                                                    breakpoints={{
                                                                        0: {
                                                                            slidesPerView: 1,
                                                                            spaceBetween: 6
                                                                        },
                                                                        500: {
                                                                            slidesPerView: 3,
                                                                            spaceBetween: 10
                                                                        },
                                                                        992: {
                                                                            slidesPerView: 5,
                                                                            spaceBetween: 30
                                                                        }
                                                                    }}

                                                                    navigation={true}
                                                                    thumbs={{ swiper: thumbsSwiper }}
                                                                    modules={[Navigation, Thumbs, Mousewheel, Autoplay, Pagination]}

                                                                    onReachEnd={() => {
                                                                        fetchMoreRelatedProducts()
                                                                    }}

                                                                >

                                                                    {productresult.sections_personal[0].products?.map((products, index) => (
                                                                        <div className="" key={products?.id}>
                                                                            <SwiperSlide>
                                                                                <ProductCard product={products} />
                                                                            </SwiperSlide>
                                                                        </div>

                                                                    ))}
                                                                </Swiper>
                                                            </div>

                                                        </div>



                                                    )
                                                    : (
                                                        <div className='no-product'>
                                                            <img src={No_Orders} style={{ width: '40%' }} alt='no-product' className='img-fluid'></img>
                                                            <p>No Products Found</p>
                                                        </div>
                                                    )}



                                            </>

                                        )}
                                </div>
                            </div>

                        </div>

                    </section >
                </>
                :
                <div className='d-flex flex-column justify-content-center align-items-center noInternetContainer'>
                    <MdSignalWifiConnectedNoInternet0 />
                    <p>{t("no_internet_connection")}</p>
                </div>}
        </>

    );

});

export default ProductHome;;