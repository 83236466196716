import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import coverImg from '../../utils/cover-img.jpg';
import { FaUserCircle, FaListAlt, FaHome, FaEdit, FaWallet } from 'react-icons/fa';
import { GoChecklist } from 'react-icons/go';
import { IoIosArrowForward, IoMdLogOut } from 'react-icons/io';
import { AiFillDelete, AiOutlineCloseCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Order from '../order/Order';
import Address from '../address/Address';
import Transaction from '../transaction/Transaction';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { removelocalstorageOTP } from '../../utils/manageLocalStorage';
import api from '../../api/api';
import { useTranslation } from 'react-i18next';
import FirebaseData from '../../utils/firebase/FirebaseData';
import { logoutAuth, setCurrentUser } from "../../model/reducer/authReducer";
import { setFilterBrands, setFilterCategory, setFilterSearch, setFilterSection } from "../../model/reducer/productFilterReducer";
import WalletTransaction from '../wallet-transaction/WalletTransaction';
import { PiWallet } from "react-icons/pi";
import { setCartProducts, setCartSubTotal, setIsGuest } from '../../model/reducer/cartReducer';


const Sellers = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const { firebase } = FirebaseData();

    const setting = useSelector((state) => state.setting);
    const user = useSelector(state => (state.user));
    const closeCanvas = useRef(null);


    const [profileClick, setprofileClick] = useState(true);
    const [orderClick, setorderClick] = useState(false);
    const [selectedButton, setSelectedButton] = useState(location?.pathname?.split("/")?.[2] || 'profile');
    const [addressClick, setaddressClick] = useState(false);
    const [transactionClick, settransactionClick] = useState(false);
    const [walletTransactionClick, setWalletTransactionClick] = useState(false);
    const [username, setusername] = useState();
    const [useremail, setuseremail] = useState();
    const [selectedFile, setselectedFile] = useState();
    const [isupdating, setisupdating] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState()
    const [city, setCity] = useState()
    const [msg, setMessage] = useState()

    // Store original values
    const [originalUsername, setOriginalUsername] = useState();
    const [originalEmail, setOriginalEmail] = useState();

    const checkIfChanged = () => {
        if (
            username !== originalUsername ||
            useremail !== originalEmail ||
            selectedFile !== null
        ) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
    };

    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
        // Add your existing button click logic here
    };

    useEffect(() => {
        document.title = "Sell Products on Beldara Quick Commerce | Sell online on Beldara | Build Brand | Grow Sell with Beldara"
    }, []);


    useEffect(() => {
        checkIfChanged();
    }, [username, useremail, selectedFile]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsOpen(true);
        }, 500);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);



    const handleFranchise = async (e) => {
        e.preventDefault();
        setisupdating(true);
        await api.update_Franchise(username, useremail, phoneNumber, city, msg, "Sellers")
            .then(response => response.json())
            .then(result => {
                if (result.status === 1) {
                    toast.success(result?.message)
                    setIsChanged(false);
                    navigate("/")

                }
                else {
                    toast.error(result.message);
                    setisupdating(false);
                }
            }).catch((error) => {
                toast.error(error);
            });


        // setuseremail("")
        // setselectedFile()
        // setusername("")
    };




    const { t } = useTranslation();


    return (
        <>

            <section id='profile'>
                <div className='container py-1'>
                    <div className='content-container row'>

                        <div className=' hide-mobile-screen col-md-1 col-1'>
                        </div>
                        <div className='table-content col-md-10 col-sm-12   '>
                            <h4></h4>


                            {profileClick
                                ?// <ProfileContent isupdating={isupdating} setisupdating={setisupdating} />
                                <>
                                    {/* <div className='d-flex flex-column'> */}
                                    <div className='heading actual-content' >
                                        {t("Seller Signup")}
                                    </div>
                                    <div className='actual-content my-5'>

                                        <div className='row'>
                                            <div className='col-md-6 img' >
                                                <img style={{ width: "90%", margin: "10px" }} className='img' src='sell-on-beldara.png'></img>
                                            </div>
                                            <form onSubmit={handleFranchise} className='col-md-6'>
                                                <div className='inputs-container'>
                                                    <input
                                                        type='text'
                                                        placeholder='Your Name'
                                                        value={username}
                                                        onChange={(e) => {
                                                            setusername(e.target.value);
                                                        }}
                                                        required
                                                    />
                                                    <input
                                                        type='email'
                                                        placeholder='Email Address'
                                                        value={useremail}
                                                        onChange={(e) => {
                                                            setuseremail(e.target.value);
                                                        }}
                                                        required
                                                    />
                                                    <input
                                                        type='tel'
                                                        placeholder='Mobile Number'
                                                        value={phoneNumber}
                                                        required
                                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                                    />
                                                    <input
                                                        type='text'
                                                        placeholder='City'
                                                        value={city}
                                                        required
                                                        onChange={(e) => setCity(e.target.value)}
                                                    />
                                                    <input
                                                        type='text'
                                                        placeholder='Message'
                                                        value={msg}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                    />

                                                </div>
                                                <button
                                                    type='submit'
                                                    disabled={!isChanged || isupdating}
                                                >
                                                    {t("Submit")}
                                                </button>
                                            </form>
                                        </div>
                                        {/* </div> */}
                                    </div></>
                                : null}

                            {orderClick
                                ? <Order />
                                : null}

                            {transactionClick
                                ? <Transaction />
                                : null}

                            {addressClick
                                ? <Address />
                                : null}

                            {walletTransactionClick
                                ? <WalletTransaction />
                                : null}


                        </div>
                        <div className='sidebar hide-mobile-screen col-md-1 col-1'>
                        </div>
                    </div>

                </div>

            </section >
        </>
    );
};

export default Sellers;
