import React, { useRef, useEffect } from 'react';
import './category.css';
import api from '../../api/api';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from '../../model/action-type';
import { Link, useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Slider from 'react-slick';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { setCategory, setSelectedCategory } from '../../model/reducer/categoryReducer';
import { clearAllFilter, setFilterCategory } from '../../model/reducer/productFilterReducer';
import ImageWithPlaceholder from '../image-with-placeholder/ImageWithPlaceholder';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import CategoryChild from './CategoryChild';


const Category = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const shop = useSelector(state => state.shop);
    const category = shop && shop?.shop?.categories;

    const selectCategory = (category) => {
        dispatch(clearAllFilter())
        // if (category?.has_child === true) {
        //     navigate(`/category/${category.slug}`);
        //     dispatch(setSelectedCategory(category));
        //     // navigate(`/category/${category.slug}`, { state: { categoryName: category.name } });
        // } else {
        //     navigate('/products');
        // }
        dispatch(setFilterCategory({ data: category?.id }));
        navigate(`/products/${category?.slug}/${category?.id}`);
    };

    return (
        <>
            {
                category?.map((Mainctg, index) => (

                    (category && (category[index].cat_active_childs?.length > 0))
                        ?
                        <>
                            <div className="row category_section_header">
                                <div className="col-md-12 col-12 d-flex justify-content-between align-items-center p-0">
                                    <div className="title d-md-flex align-items-center ">
                                        <p>{t('shop_by')} </p>
                                        <Link className='d-none d-md-block' to='/'>{Mainctg.name} <AiOutlineArrowRight size={20} className='see_category_arrow' /> </Link>
                                    </div>
                                    {/* <div className=' d-md-none'>
                                        <Link className='category_button' to='/category/all'>{t('see_all')}</Link>
                                    </div> */}
                                    {
                                        category[index].cat_active_childs?.length > 5 ? <div className=" justify-content-end align-items-ceneter d-md-flex d-none">
                                            <button className='prev-arrow-category' ><FaChevronLeft fill='black' size={20} /></button>
                                            <button className='next-arrow-category' ><FaChevronRight fill='black' size={20} /></button>
                                        </div> : <></>
                                    }

                                </div>
                            </div>
                            <div className="caegory_section_content">

                                <div className='row ' id="expandCategory">
                                    <Swiper modules={[Navigation, Pagination]}
                                        navigation={{
                                            prevEl: '.prev-arrow-category',
                                            nextEl: '.next-arrow-category',
                                        }}
                                        pagination={{ clickable: true }}
                                        breakpoints={{
                                            1200: {
                                                slidesPerView: 10,
                                                spaceBetween: 10,
                                            },
                                            1024: {
                                                slidesPerView: 8,
                                                spaceBetween: 10,
                                            },
                                            768: {
                                                slidesPerView: 6,
                                                spaceBetween: 8,
                                            },
                                            300: {
                                                slidesPerView: 4,
                                                spaceBetween: 6,
                                            },
                                        }}>
                                        {
                                            category[index].cat_active_childs?.map((ctg, index) => (
                                                < div className="col-md-12" key={index} >
                                                    <SwiperSlide className='category-container '>
                                                        {

                                                            ctg.has_child
                                                                ? (
                                                                    <Card onClick={() => selectCategory(ctg)}>
                                                                        {/* <Card.Img onError={placeHolderImage} variant='top' src={ctg.image_url} alt={ctg.subtitle} className='card-img-top category_image' /> */}
                                                                        <ImageWithPlaceholder src={ctg.image_url} alt={ctg.subtitle} className={"card-img-top category_image"} />
                                                                        <Card.Body className='card-body'>
                                                                            <Card.Title className="card-title">{ctg.name}</Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                )
                                                                : (
                                                                    <Card onClick={() => selectCategory(ctg)}>
                                                                        {/* <Card.Img onError={placeHolderImage} variant='top' src={ctg.image_url} alt={ctg.subtitle} className='card-img-top category_image' /> */}
                                                                        <ImageWithPlaceholder src={ctg.image_url} alt={ctg.subtitle} className={'card-img-top category_image'} />
                                                                        <Card.Body className='card-body'>
                                                                            <Card.Title className="card-title">{ctg.name}</Card.Title>
                                                                        </Card.Body>
                                                                    </Card>

                                                                )}
                                                    </SwiperSlide>
                                                </div>
                                            ))
                                        }
                                    </Swiper>
                                </div>
                            </div >
                        </>
                        : null
                ))
            }

        </>
    );
};

export default Category;
